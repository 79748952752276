.input-wrapper{
    margin-top: 5px;
    position: relative;
    .default-input{
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        border-radius: 4px;
        &.left{
            padding: 10px 10px 10px 40px;
        }
        &.right{
            padding: 10px 40px 10px 10px;
        }
        width: 100%;
        outline: none;
        color: #3C3A41;
    }
    .searchico{
        position: absolute;
        &.left{
            left: 16px;
        }
        &.right{
            right: 16px;
        }
        top: 28%;
        path{
            stroke: #838188;
        }
    }
}
