$primary-color: #5e1896;
$secondary-color: #228ab0;
$accent-color: #ae31a4;

$dash-menu-bar-height: 65px;
$dash-footer-height: 48px;

$viewmore-list-width: 160px;

:root {
    --toastify-toast-width: 420px;
    --rt-opacity: 1;
}