.nav-bar__container {
  .nav-bar {
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    // font-size: calc(10px + 2vmin);
    color: white;
    padding: 15px 20px;
  }
  .add-article_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #282c34;
    font-weight: 500;
    background-color: #fff;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 40px;
    margin-right: 100px;
    cursor: pointer;
    &-icon{
      margin-right: 5px;
    }
  }
}
