.menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #18161B;
    position: relative;
    .label{
        font-style: normal;
        font-weight: 400;
        margin-right: 16px;
    }
    .dd{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .dd-name{
            font-style: normal;
            font-weight: 600;
            margin-right: 5px;
            text-wrap: nowrap;
        }
        &.selected{
            color: #4D29DB;
            svg {
                path {
                  stroke: #4D29DB;
                }
              }
        }
    }
    
    &-dropdown{
        width: 285px;
        position: absolute;
        top: 30px;
        z-index: 200;
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 15px 0px 0px;
        color: #18161B;
        &.show{
            display: block;
        }
        &.hide{
            display: none;
        }
        .dropdown-header{
            padding: 0px 16px;
            &-sup{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            &-ti{
                font-style: normal;
                font-weight: 500;
            }
            &-sub{
                font-size: 12px;
                color: #56555C;
                margin-top: 8px;
            }
        }
        .dropdown-list{
            padding: 10px 0px;
            max-height: 320px;
            overflow-y: auto;
            &-it{
                padding: 15px 16px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                
                span{
                    margin-left: 10px;
                }
                .it-dt{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 60%;
                    &.dt{
                        width: 100%;
                    }
                }
                .name-it{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    .trash{
                        display: none;
                        path{
                            stroke: #18161B;
                        }
                    }
                }
                &:hover{
                    background: #F8F8FB;
                    cursor: pointer;
                    .trash {
                        display: block;
                    }
                }
                .it-prg{
                    width: 38%;
                    position: relative;
                    &-val{
                        font-size: 10px;
                        position: absolute;
                        top: 15%;
                        right: 5px;
                    }
                }
            }
        }
        .dropdown-footer{
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover{
                background: #F8F8FB;
                cursor: pointer;
            }
            .add-art{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px 16px;
                span{
                    margin-left: 10px;
                }
            }
        }
    }
}