.unfinish-stats{
    border-radius: 6px;
    border: 1px solid #F2F1F5;
    padding: 15px;
    margin-top: 15px;
    flex: 48%;
    max-width: 365px;
    height: fit-content;
    .stats-title{
        color: #56555C;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }
    .chartstats{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        .chart{
            width: 50px;
        }
        .stats{
            display: flex;
            flex-direction: column;
            width: 100%;
            .details{
                display: flex;
                flex-direction: row;
                align-items: center;
                &:not(:last-of-type){
                    margin-bottom: 2px;
                }
                .det-val{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .pill-btn{
                        width: 36px;
                        color: #fff;
                        padding: 2px 4px;
                        cursor: default;
                        font-size: 10px;
                    }
                    .name{
                        color: #3C3A41;
                        font-size: 12px;
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }
                .det-cnt{
                    color: #838188;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    svg {
                        margin-left: 4px;
                        path{
                        stroke: #838188;
                    }}
                }
            }
        }
    }
    .stats-footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        color: #56555C;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        svg {
            margin-left: auto;
            path{
                stroke: #56555C;
            }
        }
    }
}