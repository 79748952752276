.filter-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 15px !important;
    margin-right: 20px;
    border-radius: 6px !important;
    font-size: 14px !important;
    min-width: 110px;
    &:hover{
        background-color: #F2F1F5 !important;;
    }
    &.active{
        background-color: #E5E4EB !important;;
    }
    svg{
        margin-right: 10px;
    }
}
.filter-sidebar {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    width: 280px;
    height: calc(100vh - $dash-menu-bar-height - $dash-footer-height);
    z-index: 1000;
    top: $dash-menu-bar-height;
    right: 0px;
    position: fixed;
    opacity: 1;
    border-left: 1px solid #E5E4EB;
    background: #FFF;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.04);
    // padding: 16px;
    .filter-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 20px;
        font-weight: 500;
        color: #18161B;
        padding: 16px 16px 0px 16px;
    }
    .filter-content{
        overflow-y: auto;
        padding: 16px;
        .title{
            color: #56555C;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 15px;
        }
        .sts-filter, .decn-filter{
            .checkbox{
                margin-right: 13px;
                rect{
                    stroke: #CCCAD1;
                }
            }
        }
        .sts-filter{
            .sts-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 15px 0;
                .enclosed-icon{
                    margin-right: 13px;
                }
                .sts-val{
                    color: #3C3A41;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }
        .decn-filter{
            margin-top: 15px;
            .decn-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 15px 0;
                .decn-val{
                    width: 120px;
                    color: #3C3A41;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                }
                .outerProgress{
                    height: 20px !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 1px;
                }
                .innerProgress{
                    height: 90% !important;
                }
            }
        }
        .user-filter {
            margin-top: 15px;
            .usr-item{
                margin: 12px 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .usr-val{
                    color:#3C3A41;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
            .usr-inpt{
                margin-bottom: 20px;
                border-radius: 4px;
                border: 1px solid #E5E4EB;
                padding: 0 13px;
                .input-wrapper {
                    margin-top: 0px;
                    .searchico.right {
                        right: 0px;
                    }
                }
                .default-input{
                    border: none;
                    padding-left: 0px;
                    padding-right: 20px;
                }
                .usr-lst{
                    max-height: 198px;
                    overflow-y: auto;
                }
                .usr-itm-a{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 10px 0;
                    cursor: pointer;
                    .close{
                        margin-left: auto;
                        path{
                            stroke: #A2A1A8;
                        }
                    }
                    .member_avatar-letter{
                        font-size: 10px;
                        font-weight: 600;
                        margin-right: 8px;
                    }
                    .usr-tt{
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        overflow: hidden;
                        color: #3C3A41;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .drug-filter{
            margin-top: 15px;
            margin-bottom: 15px;
            .drug-input{
                width: auto;
            }
            .drug-list{
                height: 166px;
                overflow-y: auto;
            }
        }
    }
    .filter-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        .retry{
            color: #3C3A41;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }
            svg {
                margin-right: 10px;
                path {
                stroke: #3C3A41;
            }}
        }
    }
}