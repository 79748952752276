.auto-import-modal{
    padding-top: 6px;
    width: 580px;
    height: 670px;
    .modalContent{
        padding: 0;
    }
    .org-header{
        padding: 0 20px;
    }
    .tabs {
        padding: 0 20px;
    }
}

.scheduled-tab{
    height: 570px;
    display: flex;
    flex-direction: column;
    .querysearch{
        padding: 0 20px;
    }
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text{
            color: #838188;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }
        .create{
            display: flex;
            align-items: center;
            padding: 8px 16px;
            svg{
                margin-right: 10px;
                path{
                    stroke: #FFF;
                }
            }
        }
    }
    .querylst{
        margin-top: 8px;
        height: 510px;
        overflow: auto;
        .no-data{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: inherit;
            color: #838188;
        }
        .query{
            padding: 10px 0;
            .rw{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .query-kw{
                    color: #3C3A41;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                }
                .ctry{
                    color: #838188;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    span{
                        color: #56555C;
                    }
                }
                .pill-btn{
                    color: #4D29DB;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    border-radius: 4px;
                    background: #F8F8FB !important;
                    padding: 5px 8px;
                }
            }
        }
    }
    .edit-sched{
        padding: 0 20px;
        .bck{
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            color: #838188;
            cursor: pointer;
            svg{
                margin-right: 10px;
                path{
                    stroke: #838188;
                }
            }
        }
        .inpt-item{
            margin-top: 15px;
            .labl{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .opt{
                    color: #838188;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
            .search-inpt{
                input:focus{
                    border: 1px solid #56555C;
                }
            }
            
            &.half{
                display: flex;
                flex-direction: row;
                gap: 20px;
                .full{
                    width: 100%;
                }
            }
        }
        label{
            color: #56555C;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
        .default-input{
            padding: 10px !important;
        }
    }
    .edit-footer{
        margin-top: auto;
        .footer-opt{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 20px;
            .cbtn{
                margin-left: auto;
            }
            .rbtn{
                color: #3C3A41;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                svg {
                    margin-right: 10px;
                    path{
                    stroke: #3C3A41;
                }}
            }
        }
    }
    
}

.select-input{
    &.card-menu__options {
        max-height: 200px;
        overflow: auto;
        &.country{
            width: 268px;
        }
        .card-menu__options_list{
            &:hover{
               color: #4D29DB;
            }
        }
    }
    &.card-menu__options.datepicker {
        width: auto;
        max-height:fit-content;
        left: -135px !important;
        .rdp-day_selected{
            background-color: #7856FF !important;
            color: #fff !important;
        }
    }
}
.select-inpt{
    svg{
        cursor: pointer;
        &:hover{
            path {
                stroke: #56555C;
            }
        }
    }
    .input-wrapper.active{
        svg path {
            stroke: #56555C;
        }
    }
    .input-wrapper.active .default-input{
        border: 1px solid #56555C;
    }
}