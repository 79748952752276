.org-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .org-det{
        display: flex;
        flex-direction: column;
        .title{
            color: #18161B;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
        }
        .subtitle{
            color: #56555C;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            margin-top: 5px;
        }
    }
    .close-icon{
        cursor: pointer;
    }
}