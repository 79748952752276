*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:disabled {
  background-color: #E5E4EB !important;
  color: #CCCAD1 !important;
  cursor: not-allowed;
}

.btn {
  padding: 5px 10px;
  margin: 0 5px;
  height: 30px;
}

hr {
  border: none;
  height: 1px;
  background-color: #E5E4EB;
}

.menu-bar__withlogo {
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .logout{
    cursor: pointer;
  }
  .logo{
    width: 200px;
    // height: 40px;
  }
}

.dropzone{
  background-color: #546bf6 !important;
  &-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    pointer-events: none;
    &-title{
      font-size: 36px;
    }
    &-subtitle{
      font-size: 16px;
      margin-top: 15px;
    }
  }
}

.react-tiny-popover-container{
  z-index: 10000;
}

.parentModal{
  position: absolute;
}


/* 
REACT TAGS
**/

div.ReactTags__tags {
  position: relative;
}
/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 14px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}
/* Styles for selected tags */
div.ReactTags__selected {
  max-height: 320px;
  overflow: auto;
}
div.ReactTags__selected span.ReactTags__tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #18161B;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500;
  cursor: move;
  border-radius: 4px;
  background:#E5E4EB;
  margin-bottom: 5px;
  margin-right: 5px;
}
div.ReactTags__selected a.ReactTags__remove {
  // color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}
button.ReactTags__remove{
  color: #A2A1A8;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  background: none;
}

svg.ReactTags__remove{
  width: 15px;
  height: 15px;
  color: #A2A1A8;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  background: none;
  path{
    stroke: #A2A1A8;
  }
}

/* React tool tip*/

.react-tooltip{
  z-index: 99999999 !important;
}

.tooltip {
  border-radius: 4px;
  border: 1px solid  #E5E4EB;
  background: #FFF;
  box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
  font-size: 12px;
  color:#18161B;
  font-family: Helvetica Neue;
  z-index: 1000;
  .react-tooltip-arrow{
    box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
    border: 1px solid  #E5E4EB;
  }
}

.tooltip-dark {
  border-radius: 4px;
  border: 1px solid  #18161B;
  background: #18161B;
  box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
  font-size: 12px;
  color:#FFF;
  font-family: Helvetica Neue;
  z-index: 1000;
  .react-tooltip-arrow{
    box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
    border: 1px solid  #18161B;
  }
}
.react-tooltip__place-right-start, .react-tooltip__place-right, .react-tooltip__place-right-end{
  .react-tooltip-arrow{
    border-top: none;
    border-right: none;
  }
}
.react-tooltip__place-top-start, .react-tooltip__place-top, .react-tooltip__place-top-end{
  .react-tooltip-arrow{
    border-top: none;
    border-left: none;
  }
}

.react-tooltip__place-bottom-start, .react-tooltip__place-bottom, .react-tooltip__place-bottom-end{
  .react-tooltip-arrow{
    border-bottom: none;
    border-right: none;
  }
}
.react-tooltip__place-left-start, .react-tooltip__place-left, .react-tooltip__place-left-end{
  .react-tooltip-arrow{
    border-bottom: none;
    border-left: none;
  }
}

/* Ellipsis */
.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.multiline-word {
  inline-size: 250px;
  overflow-wrap: break-word;
  hyphens: manual;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Toast */
.Toastify__toast{
  border-radius: 6px;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
}
.Toastify__toast{
  &--error{
    border: 1px solid #EE0B0B;
    background: linear-gradient(0deg, rgba(255, 218, 218, 0.20) 0%, rgba(255, 218, 218, 0.20) 100%), #FFF;
  }

  &--info{
    border: 1px solid #E5E4EB;
    background: var(--platform-white, #FFF);
  }

  &--warning{
    border: 1px solid #E1B000;
    background: linear-gradient(0deg, #FFFCEF 0%, #FFFCEF 100%), linear-gradient(0deg, rgba(255, 243, 203, 0.30) 0%, rgba(255, 243, 203, 0.30) 100%), #FFF;
  }

  &--success{
    border: 1px solid #06B54C;
    background: linear-gradient(0deg, #F5FEF8 0%, #F5FEF8 100%), linear-gradient(0deg, rgba(214, 250, 228, 0.25) 0%, rgba(214, 250, 228, 0.25) 100%), #FFF;
  }
  
  .toast-msg{
    .title{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #3C3A41;
    }
    .msg{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #838188;
      margin-top: 10px;
      max-height: 75px;
      overflow: auto;
    }
  }
  svg {
    margin-top: 5px;
    path{
    stroke: #3C3A41;
  }
}
}

.system-message{
  .msg{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.success{
    background: #06B54C !important;
    color: #FFFFFF !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    .msg{
      svg {
        margin: 0;
        margin-right: 15px;
        path {
          stroke: #FFFFFF;
        }
      }
    }
  }
  &.warning{
    background: #EE0B0B !important;
    color: #FFFFFF !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    .msg{
      svg {
        margin: 0;
        margin-right: 15px;
        path {
          stroke: #FFFFFF;
        }
      }
    }
  }
  &.info{
    background: #FFF !important;
    color: #3C3A41 !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    border: 1px solid #E5E4EB;
    .msg{
      svg {
        margin: 0;
        margin-left: 15px;
        path {
          stroke: #3C3A41;
        }
      }
    }
  }
}

