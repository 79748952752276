.viewmore-list{
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    width: $viewmore-list-width;
    .placeholder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div.text{
            margin-right: 3px;
        }
    }
    .menu-item-dropdown{
        width: 140px;
        max-width: $viewmore-list-width;
    }
    .list-menu{
        top: 17px;
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        padding: 6px 10px;
        border-radius: 4px;
        .label{
            padding: 3px 0;
        }
    }
}