.join__page{
    // height: calc(100vh - 73px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
        color: #18161B;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 42px;
    }
    .sub-title{
        color: #18161B;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-top: 5px;
    }
    .info-box{
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        height: 66px;
    }
    .comp-info{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 320px;
    }
    .join-btn{
        margin-top: 20px;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
    }
}
.no-invite{
    .sub-title{
        margin-top: 30px;
        text-align: center;
    }
}