.completed-widget{
    .cpl-download{
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-left: 6px;
            path {
            stroke: #838188;
        }}
    }

    .rs-main{
        height: 200px;
    }

    .completed-main{
        margin-top: 15px;
        height: 150px;
        overflow-y: auto;
    }

    .completed-itm{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding: 10px 0;
        border-bottom: 1px solid #F8F8FB;
        .itm-name{
            color: #56555C;
            font-style: normal;
            font-weight: 400;
        }
        .itm-progres{
            width: 40%;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #7856FF;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            .outerProgress{
                margin-right: 10px;
            }
        }
        .itm-info{
            color: #3C3A41;
            text-align: right;
            font-style: normal;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                margin-right: 15px;
                font-size: 13px;
            }
            svg{
                cursor: pointer;
            }
        }
    }
    .completed-footer{
        padding: 6px 0;
    }
}