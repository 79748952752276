.pill-btn {
  border: none;
  color: #20262d;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 400;
  margin-left: 12px;
}
