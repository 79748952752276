.inv-modal{
    .modalContent{
        padding: 20px;
        margin-top: 0;
        width: 500px;
        height: auto;
    }
    .inv-list{
        margin-top: 10px;
        max-height: 300px;
        overflow: auto;
        &-itm{
            margin-top: 10px;
            .itm-title{
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: #3C3A41;
            }
            .price-row{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 7px;
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
                &-dt{
                    float: left;
                    width: 33.33%;
                    font-style: normal;
                    font-size: 12px;
                    color: #36343B;
                    span{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}