.entity-radio-button{
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid #36343B;
    border-radius: 45px;
    padding: 3px;
    margin-right: 10px;
    cursor: pointer;
    .inner-radio{
        width: 12px;
        height: 12px;
        border-radius: 45px;
    }
}