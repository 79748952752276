.country-suggestion-card{
    border-bottom: 1px solid #CCCAD1;
    padding: 12px 15px;
    &__header{
        margin-bottom: 10px;
    }
    .card-name{
        font-style: normal;
        font-weight: 500;
        color: #18161B;
    }
    .select-input-options{
        max-height: 90px;
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: auto;
        border-top: none;
        margin-top: -1px;
    }
    .suggestion-btn{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        .button{
          padding: 6px 24px;
          width: 100%;
        }
        &__cancel {
          padding: 6px 15px;
          border-radius: 4px;
          width: 100%;
          margin-right: 10px;
          text-align: center;
          cursor: pointer;
        }
        &__confirm{
          padding: 6px 15px;
          border-radius: 4px;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
      }

      .cardsuggestion-confirm {
        margin: 5px 0 0 0 !important;
        width: 100%;
        margin: 15px 0;
        align-items: center;
      }
}