.search-cta{
    display: flex;
    width: 140px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    border: 1px solid #E5E4EB;
    margin-right: 40px;
    color: #A2A1A8;
    svg path {
        stroke: #A2A1A8;
    }
    cursor:text;
}

.main-sb{
    width: 100%;
    margin-right: 16px;
    .search-wrapper{
        position: relative;
    }
    .result-container{
        position: absolute;
        z-index: 100;
        width: 100%;
        background-color: #FFF;
        border-radius: 6px;
        border: 1px solid #E5E4EB;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background: #FFF;
        box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
        .category{
            &-title{
                padding: 10px 20px;
                background-color: #F8F8FB;
                color: #838188;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                &.active{
                    cursor: pointer;
                }
            }
            &-list{
                padding: 8px 20px;
                color: #3C3A41;
                font-style: normal;
                font-weight: 500;
                .user-itm{
                    padding: 10px 0 !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #3C3A41;
                    cursor: pointer;
                    .user-nm {
                        color: #3C3A41;
                        margin-left: 20px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
                .proj-name{
                    padding: 10px 0;
                    color: #3C3A41;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    cursor: pointer;
                    overflow-wrap: break-word;
                }
            }
        }
    }
    .searchbar.open .default-input {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .searchbar {
        margin: 0;
        .default-input {
            font-size: 16px;
            padding: 10px 40px 10px 20px !important;
            color: #3C3A41;
            border-radius: 6px !important;
        }
        .searchico{
            top: 31%;
            path{
                stroke: #18161B;
            }
            &.tx{
                color:  #A2A1A8;
                cursor: pointer;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
}