.pageAnnotationsContainer{
    position: relative;
    span.select-coated{
        border: 1px solid #4d29db !important;
        background: #4d29db24 !important;
        .selection-info{
            background: #4d29db !important;
        }
    }
}

.selection-info{
    position: absolute;
    transform:translateY(-100%);
    font-weight: bold;
    font-size: 12px;
    user-select: none;
    * {
        margin: 2px;
        vertical-align: middle;
    }
    .action-icon{
        margin-right: 4px;
        margin-left: 4px;
    }
}

.selection-entity-dropdown{
    position: absolute;
    width: 300px;
    top: calc(100% + 2px);
    background: #FFFFFF;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    z-index: 10000;
    border: 1px solid #E5E4EB;
    padding: 2px 0;
    input.selection-entity-input {
        width: 270px;
        padding: 10px 16px 10px 12px;
        outline: none;
        border: none;
        font-size: 16px;
        color: #18161B;
        border-radius: 6px;
    }
    .selection-entity-drop{
        max-height: 270px;
        overflow-y: scroll;
        font-family: 'Helvetica Neue';
        border-top: 1px solid #E5E4EB;
        &-list{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 15px;
            &:hover{
                background-color: #F8F8FB;
                cursor: pointer;
            }
            .dot-color{
                width: 17px;
                height: 17px;
                border-radius: 16px;
            }
            .entity-name{
                margin-left: 15px;
            }
        }
    }
    svg{
        position: absolute;
        right: 20px;
        top: 12px;
        path{
            stroke: #18161B;
        }
    }
}