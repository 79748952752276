.colab-user-modal{
    padding-top: 20px;
    width: 450px;
    .modalContent{
        margin-top: 0;
        padding: 0;
        padding-bottom: 20px;
    }
    .subtitle{
        color: #3C3A41;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        margin-top: 20px;
    }
    .card-menu__options {
        left: -86px;
    }

    .colab-header{
        margin-bottom: 15px;
        padding: 0 20px;
        .title{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color: #18161B;
            margin-bottom: 5px;
        }
        .sub-title{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: #56555C
        }
    }

    .invite-usr{
        display: flex;
        flex-direction: row;
        padding: 0 20px;
        .dropdown-sel{
            width: 100%;
        }
        button {
            margin-left: 10px;
            background: #7856FF;
            border-radius: 4px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 15px;
            padding: 10px 30px;
            cursor: pointer;
            border: none;
            height: 38px;
        }
    }
    .colab-content{
        .subtitle{
            padding: 0 20px;
        }
        .pending-tb{
            font-size: 14px;
            .action{
                svg{
                    width: 18px;
                }
            }
        }
            .user-itm{
                padding-left: 20px;
                padding-right: 20px;
                &:hover, &.active{
                    background-color: #F8F8FB;
                }
            }
    }
    .colab-footer{
        display: flex;
        align-items: center;
        padding: 14px 20px 0;
    }
}
