.overlap-avatar{
    display: flex;
    margin: auto;
    padding:0px;
    flex-direction: row;
    align-items: center;
    img, .member_avatar-letter{
        border: 1.5px solid #FFFFFF;
        background-color: #596376;
	    display: block;
		transition: margin 0.1s ease-in-out;
		overflow: hidden;
		margin-left: -10px;
    }
    .member_avatar-letter{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}