.unfinished-widget{
    .unfinished-main{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 15px;
        height: 398px;
        overflow-y: auto;
    }
    .period-disp{
        color: #838188;
        font-size: 14px;
        font-style: normal;
        margin-top: 10px;
    }
    .reload{
        path {
            stroke: #838188;
        }
    }
}
.rs-main{
    height: 435px;
}
.center-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #838188;
}