.literature-review__page {
    // .pageAnnotationsContainer{
    //     canvas{
    //         width: 100% !impzzzortant;
    //     }
    // }
}
.article__pdf{
    .progress-text{
        font-style: normal;
        font-weight: 400;
        color: #3C3A41;
        margin-top: 10px;
    }
}

.centerOnPage{
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
