.info-box{
    border: 1.5px solid #000000;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .team-info{
        font-style: normal;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .head{
            color: #18161B;
            font-weight: 500;
            line-height: 24px;
            font-size: 16px
        }
        .sub-head{
            color: #56555C;
            font-weight: 400;
            font-size: 12px;
        }
    }
}