.table-title{
    font-weight: 500;
}
.article-listing{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-article{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .msg{
        margin-top: 20px;
        font-size: 24px;
        color: #56555C;
        letter-spacing: 1px;
    }
    height: 85%;
}

.table-wrapper{
    margin-top: 18px;
}

.article-listing__table{
    border-collapse: collapse;
    width: 100%;

    display: block;
    height: calc(100vh - #{$dash-menu-bar-height} - #{$dash-footer-height} - 128px);
    overflow-y: auto;

    col.hover {
        background: #FBFBFD !important;
    }

    td {
        padding: 18px;
    }

    tr:hover, tr.active {
        background: #FBFBFD;
        color: #18161B !important;
    }

    thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
        // background-color: #ffffff;
        color: #56555C;
        background-color: #F8F8FB;
        z-index: 10;
        tr{
            border: none !important;
        }
    }

    tbody{
        overflow-y: auto;
    }

    th {
        padding: 8px 14px;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;
        &:hover{
            color: #18161B;
            svg path{
                stroke: #18161B;
            }
        }
    }

    td .table-opt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pdf-dld-btn{
            background-color: #ecf1f8;
            color: #747c95;
            padding: 5px 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            &__text{
                margin-right: 10px;
            }
        }
        .view-text{
            cursor: pointer;
            font-size: 14px;
            text-decoration: none;
            color: #747c95;
        }
        .close-icon{
            background-color: #ecf1f8;
            width: 25px;
            height: 25px;
            padding: 7px;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}

.dash{
    height: 100vh;
    display: flex;
    flex-direction: column;
    .not-found{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 20px;
    }
}

.dash-menubar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: $dash-menu-bar-height;
    background: #F8F8FB;
    border-bottom: 1px solid #E5E4EB;
    padding: 0 30px;
    .company-image {
        max-height: 50px;
        max-width: 120px;
        // margin: 10px;
    }
    .leftbar, .rightbar{
        display: flex;
        flex-direction: row;
        align-items: center;
        .menu-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #18161B;
            position: relative;
            .label{
                font-style: normal;
                font-weight: 400;
                margin-right: 16px;
            }
            .dd{
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                .dd-name{
                    font-style: normal;
                    font-weight: 600;
                    margin-right: 5px;
                    max-width: 160px;
                }
                &.selected{
                    color: #4D29DB;
                    svg {
                        path {
                          stroke: #4D29DB;
                        }
                      }
                }
            }
            
            &-dropdown{
                width: 285px;
                position: absolute;
                top: 30px;
                z-index: 10000;
                background: #FFFFFF;
                border: 1px solid #E5E4EB;
                box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                padding: 15px 0px 0px;
                color: #18161B;
                &.show{
                    display: block;
                }
                &.hide{
                    display: none;
                }
                .dropdown-header{
                    padding: 0px 16px;
                    &-sup{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    &-ti{
                        font-style: normal;
                        font-weight: 500;
                    }
                    &-sub{
                        font-size: 12px;
                        color: #56555C;
                        margin-top: 8px;
                    }
                }
                .dropdown-list{
                    padding: 10px 0px;
                    max-height: 320px;
                    overflow-y: auto;
                    &-it{
                        padding: 15px 16px;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        span{
                            margin-left: 10px;
                        }
                        .it-dt{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 60%;
                            &.dt{
                                width: 100%;
                            }
                        }
                        .name-it{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;
                            .trash{
                                display: none;
                                path{
                                    stroke: #18161B;
                                }
                            }
                        }
                        &:hover{
                            background: #F8F8FB;
                            cursor: pointer;
                            .trash {
                                display: block;
                            }
                        }
                        .it-prg{
                            width: 38%;
                            position: relative;
                            &-val{
                                font-size: 10px;
                                position: absolute;
                                top: 15%;
                                right: 5px;
                            }
                        }
                    }
                }
                .dropdown-footer{
                    // border-top: 1px solid #E5E4EB;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    &:hover{
                        background: #F8F8FB;
                        cursor: pointer;
                    }
                    .add-art{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 5px 16px;
                        // &:hover{
                        //     background: #F8F8FB;
                        //     cursor: pointer;
                        // }
                        span{
                            margin-left: 10px;
                        }
                    }
                }
            }

            &-dropdown.datedd{
                width: 290px;
                .dropdown-list{
                    max-height: 350px;
                    &-it{
                        span{
                            margin-left: 0;
                        }
                    }
                }
                .dt-flt-item{
                    padding: 15px 20px 10px 20px;
                    .flt-item-it{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // padding: 15px 20px;
                        // justify-content: space-between;
                        .it-nm{
                            width: 52%;
                        }
                        .wk-inpt{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 48%;
                            input{
                                width: 70px;
                                padding: 6px;
                                text-align: center;
                                outline: none;
                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                            }
                            button{
                                background: #7856FF;
                                border-radius: 4px;
                                padding: 8px 10px;
                                border: none;
                                text-decoration: none;
                                color: #ffffff;
                                cursor: pointer;
                            }
                        }
                    }
                    .flt-err{
                        font-size: 11px;
                        color: #ff0000;
                        margin-top: 5px;
                    }
                    
                }
            }
            &-dropdown.projectmdd{
                width: 315px;
            }
            &-dropdown.decisn{
                width: 300px;
                .it-dt {
                    span{
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .rightbar{
        display: flex;
        flex-direction: row;
        align-items: center !important;
        margin-left: 10%;
        .menu{
            display: flex;
            flex-direction: row;
            align-items: center !important;
        }
        .menu-item{
            &-dropdown.noti{
                width: 380px;
                top: 40px !important;
                right: -10px !important;
                .dropdown-list{
                    max-height: 400px;
                }
                .dropdown-list-it{
                    padding: 0px 16px;
                    &:hover{
                        background: none;
                    }
                    &:not(:last-of-type) {
                        .noti-item{
                            border-bottom: 1px solid #F8F8FB;
                        }
                    }
                    &.unread{
                        background: #F8F8FB;
                    }
                }
                .unread-not{
                    color: #18161B;
                    font-weight: 400;
                }
                .noti-list{
                    padding: 10px 16px;
                }
                .noti-item{
                    display: flex;
                    flex-direction: column;
                    padding: 15px 0;
                    width: 100%;
                    // &:hover{
                    //     background: #F8F8FB;
                    // }
                    &-detail{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .user-avatar{
                            position: relative;
                            .read-dot{
                                position: absolute;
                                top: 0;
                                width: 8px;
                                height: 8px;
                                background-color: #ee0a0b;
                                border-radius: 45%;
                                border: 1px solid #F8F8FB;
                            }
                        }
                        .member_avatar-letter{
                            font-size: 13px;
                        }
                        .let-ccle{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #D0C8F3;
                            border-radius: 32px;
                            width: 30px;
                            height: 30px;
                            font-size: 14px;
                            font-weight: 500;
                        }
                        .let-type{
                            display: flex;
                            flex-direction: column;
                            margin-left: 10px;
                            .user-name{
                                font-size: 14px;
                                font-weight: 500;
                                color: #18161B;
                            }
                            .user-type{
                                color: #56555C;
                                font-weight: 400;
                                font-size: 13px;
                            }
                        }
                        .frm-now{
                            color: #3C3A41;
                            font-weight: 400;
                            font-size: 13px;
                        }
                    }
                    &-desc{
                        color: #3C3A41;
                        font-weight: 400;
                        margin-top: 10px;
                        &.unread{
                            font-weight: 500;
                        }
                    }
                }
            }
            &-dropdown.acct{
                width: 280px;
                top: 40px !important;
                right: -10px !important;
                .dropdown-header {
                    padding: 0px 16px 10px 16px;
                }
                .acct-item{
                    padding: 15px 20px;
                    color: #3C3A41;
                    font-weight: 500;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 10px;
                    }
                    &:hover{
                        background: #F8F8FB;
                    }
                }
                .dropdown-list{
                    padding: 5px 0;
                }
            }
        }
        // .filter-btn{
        //     background-color: #E5E4EB;
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: center;
        //     align-items: center;
        //     padding: 11px 15px;
        //     margin-right: 20px;
        //     color: #18161B;
        //     border-radius: 6px;
        //     font-size: 14px;
        //     min-width: 110px;
        //     svg{
        //         margin-right: 10px;
        //     }
        // }
    }
    &.wf .rightbar{
        width: 100%;
    }
}

.dash-content{
    padding: 22px 35px 10px 35px;
    height: calc(100vh - #{$dash-menu-bar-height} - #{$dash-footer-height});
    display: flex;
    flex-direction: column;
    &-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #18161B;
        height: 87px;
        .dash-content-title{
            font-style: normal;
            font-weight: 700;
            display: flex;
            flex-direction: row;
            margin-left: 6px;
            font-size: 18px;
            .edit{
                svg{
                    margin-right: 6px;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: 500;
            }
        }

        &-strip{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left-menu{
                display: flex;
                flex-direction: row;
            }
        }
        .strip-dw{
            margin-top: 15px;
        }
    }
    &-filter{
        display: flex;
        flex-direction: row;
        align-items: center;
        .filter-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-left: 28px;
            &:first-of-type{
                margin-left: 6px;
            }
            &-name{
                font-size: 15px;
                color: #3C3A41;
                margin-left: 8px;
            }
        }
        .filter-item:hover{
            .filter-item-name{
                color: #4D29DB;
            }
            svg{
                rect, path{
                        stroke: #4D29DB;
                    }
            }
            svg.slt-icon{
                path {
                    stroke: white;
                }
            }
            
        }
        .menu-item .filter-item{
            margin-left: 28px;
        }

    }
    .article-listing__table{
        border: none;
        color: #3C3A41;
        td {
            padding: 17px 14px !important;
            &.box{
                padding: 17px 6px !important;
            }
        }
        th {
            .r-sort{
                display: flex;
                flex-direction: row;
                align-items: center;
                svg path{
                    stroke: #18161B;
                }
            }
        }
        .title-h,.project-h,.date-h,.decision-h, .prob-h,.user-h,.status-h{
            display: flex;
            flex-direction: row;
            align-items: center;
            .sort-btn{
                margin-left: 15px;
            }
        }
        tr{
            border-bottom: 1px solid #E5E4EB;

            td:last-child {
                text-align: right;
            }

            td {
                .art-box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                &.art-title{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }
                &.art-date{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    text-align: left;
                    color: #3C3A41;
                }
                &.art-deci{
                    white-space: nowrap;
                }
                &.art-creator{
                    .creator-name{
                        margin-left: 10px;
                    }
                    .creator-det{
                        display: inline-flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        .member_avatar-letter{
                            font-size: 13px;
                            font-weight: 500;
                            color: #000;
                        }
                    }
                }
                &.art-status{
                    .status{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        span{
                            margin-left: 10px;
                        }
                    }
                    
                }
                .pdf-btn{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-right: 25px;
                    font-weight: 500;
                    font-size: 14px;
                    cursor: pointer;
                    span{
                        margin-right: 10px;
                    }
                }
                .view-btn{
                    font-weight: 500;
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background: #F8F8FB;
                    padding: 6px 12px;
                    border-radius: 4px;
                    cursor: pointer;
                    text-decoration: none;
                    color: #3C3A41;
                    span{
                        margin-right: 10px;
                    }
                    &.hover{
                        background: #E5E4EB;
                    }
                }
                &.art-options{
                    .opt{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        .close-btn{
                            margin-left: auto;
                            cursor: pointer;
                            &:hover{
                                path {
                                    stroke: #4D29DB;
                                }
                            }
                        }
                    }
                }
            }
        }
        // tr:nth-child(even){
        //     background-color: inherit;
        // }
    }
    .dash-body{
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-upload{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -150px;
            .title{
                font-style: normal;
                font-weight: 500;
                // font-size: 16px;
                text-align: center;
                color: #18161B;
            }
            .subtitle{
                font-style: normal;
                font-weight: 400;
                color: #3C3A41;
                font-size: 14px;
            }
            svg{
                margin-top: 20px;
                margin-bottom: 10px;
                path{
                    stroke: #7856FF;
                }
            }

            .pdf-upload-input{
                margin-top: 20px;
                width: 100%;
                .upload-btn{
                    display: block;
                    padding: 0.4rem 1rem;
                    color: #FFFFFF;
                    background-color: #7856FF;
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    border-radius: 4px;
                }
            }
        }
        &-npj{
            margin-top: -150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title{
                font-style: normal;
                font-weight: 500;
                text-align: center;
                color: #18161B;
            }
            .subtitle{
                font-style: normal;
                font-weight: 400;
                color: #3C3A41;
                font-size: 14px;
            }
            svg{
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .add-btn{
                margin-top: 30px;
                background: #7856FF;
                border-radius: 4px;
                color: #FFFFFF;
                text-align: center;
                padding: 0.4rem 1rem;
                width: 100%;
                cursor: pointer;
                &:hover{
                    background: #4D29DB;
                }
            }
        }
    }
}

.dash-colaborator{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #18161B;
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
        margin-right: 22px;
    }
    .colaborator{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .add-colab{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            cursor: pointer;
            margin-left: -8px;
            z-index: 10;
            background-color: #7856FF;
            svg {
                path {
                    stroke: #FFF;
                }
            }
            &:hover{
                background-color: #4D29DB;
            }
            &.active{
                background-color: #D0C8F3;
                svg {
                    path {
                      stroke: #4D29DB;
                    }
                  }
            }
        }
        .extra-colab{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 31px;
            height: 31px;
            border: 1.5px solid #FFF;
            border-radius: 100px;
            cursor: pointer;
            margin-left: -10px;
            z-index: 10;
            background: #E5E4EB;
            color: #18161B;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }
    }
}

.addproject-modal{
    &-input{
        border: 1px solid #E5E4EB;
        border-radius: 4px;
        padding: 9px 10px;
        width: 100%;
        outline: none;
        &::placeholder {
            color: #A2A1A8;
        }
    }
    &-btn{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        button{
            border-radius: 4px;
            width: 100%;
            padding: 0.6rem 1rem;
            border: none;
            text-decoration: none;
            cursor: pointer;
        }
        .cancel{
            margin-right: 5px;
            background: #F8F8FB;
            color: #3C3A41;
        }
        .create{
            margin-left: 5px;
            background: #7856FF;
            color: #FFFFFF;
            &.disable{
                background: #E5E4EB;
                color: #CCCAD1;
            }
        }
    }
}