.card-menu{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__icon{
        cursor: pointer;
        &.disable{
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    .user-rl{
        width: 71px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        border: 1px solid #D0C8F3;
        border-radius: 4px;
        padding: 4px 5px;
        margin-left: 6px;
        text-transform: capitalize;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        &:disabled{
            cursor: not-allowed;
        }
        span{
            margin-right: 5px;
        }
    }
    button.user-rl{
        background-color: #FFFFFF;
    }
    &__options{
        position: absolute;
        left: -114px;
        top: 12px;
        width: 130px;
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 6px;
        box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
        &.nest{
            left: -155px;
            top: 5px;
            width: 150px;
            .card-menu__options_list{
                display: flex;
                flex-direction: row;
                align-items: center;
                .ic{
                    margin-right: 5px;
                }
                .unsel rect{
                    stroke: #CCCAD1;
                }
            }
        }
        &_list{
            position: relative;
            padding: 6px 12px;
            color: #3C3A41;
            &:hover, &.active{
                background-color: #F8F8FB;
                cursor: pointer;
                color: #18161B;
            }
            &.remove, &.delete{
                color: #EE0B0B;
            }
        }
        // &.hide{
        //     display: none;
        // }
        // &.show{
        //     display: block;
        // }
        z-index: 10;
        .role-sec{
            padding: 5px 10px;
            .title{
                color: #56555C;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
            }
            .chk-role{
                display: flex;
                flex-direction: row;
                margin: 10px 0;
                font-weight: 500;
                color: #3C3A41;
                cursor: pointer;
                .chk-icon{
                    width: 40px;
                }
            }
        }
    }
}
.u-role{
    svg.role-mk{
        margin-right: 10px;
    }
    .card-menu__options{
        width: 150px;
        left: -65px !important;
        top: 26px !important;
        border-radius: 7px;
        &_list{
            margin: 5px 0;
            padding: 5px 15px;
        }
    }
}