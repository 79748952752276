.member_avatar, .member_avatar-letter {
  border-radius: 50%;
  filter: drop-shadow(0px 3px 10px rgba(255, 255, 255, 0.25));
}
.member_avatar-letter{
  text-transform: uppercase;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 32px;
  font-size: 14px;
}
