.auto-assign{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #18161B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    .switch-slider{
        margin-right: 10px;
    }
    .info-icon{
        margin-left: 10px;
    }
}