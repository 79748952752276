.vertical-line {
  border-left: 1px solid #E5E4EB;
  height: 35px;
}

.literature-menubar {
  background: #F8F8FB;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  font-family: 'Helvetica Neue';
  border-bottom: 1px solid #CCCAD1;
  .leftbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .go-back {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      border-radius: 50%;
      margin-right: 15px;
      cursor: pointer;
      svg {
        color: #727e8a;
      }
    }
    .page-metadata {
      margin-left: 12px;
      font-family: 'Helvetica Neue';
      .page-title {
        font-weight: 700;
        font-size: 18px;
        color: #18161B;
      }
      .last-accessor {
        font-size: 12px;
        color: #18161B;
        letter-spacing: 0.1px;
        margin-top: 4px;
        width: 235px;
        span.value{
          color: #838188;
        }
        span.seperator{
          color: #E5E4EB;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  .rightbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    .workflow{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      &-title{
        font-style: normal;
        font-weight: 400;
        margin: 0 12px 0 4px;
        color: #18161B;
      }
      &-icon{
        margin: 0 4px;
      }
    }
    .workflow-u-icon{
      margin: 0 4px;
    }
    .workflow-popup-menu{
      position: absolute;
      left: -60px;
      top: 43px;
      width: 260px;
      background: #FFFFFF;
      border: 1px solid #E5E4EB;
      box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 15px 0;
      z-index: 1000;
      &.show{
        display: block;
      }
      &.hide{
        display: none;
      }
      .heading {
        padding: 10px 15px;
        .title{
          color: #18161B;
          font-weight: 500;
        }
        .sub-title{
          font-size: 12px;
          color: #56555C;
          margin-top: 5px;
        }
      }
      .menu-list{
        color: #36343B;
        .list-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 15px;
          cursor: pointer;
          &:hover{
            background-color: #F8F8FB;
          }
          .item-icon{
            margin-right: 15px;
          }
        }
      }
    }
    .menu-item-dropdown{
      right: 0;
      top: 38px;
    }
  }
}

.literature-toolbar {
  background: #ebecf2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 25px;
  border: 2px solid #ebecf2;
  .leftbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #20262d;
  }
}

.literature-loading {
  background: #1a6efd;
  color: #ebecf2;
  width: 100%;
  height: 2em;
  font-size: large;
  font-weight: bold;
  line-height: 2em;
  text-align: center;
  justify-content: center;
}

.literature__body {
  height: calc(100vh - 71px);
  .article {
    font-family: 'Georgia';
    font-style: normal;
    // border-right: 1px solid #ebecf2;
    padding: 100px 8% 10px 8%;
    &__pdf{
      // padding: 100px 6% 10px 6%;
      padding: 60px 3% 10px 3%;
    }
    white-space: pre-line;
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    &-title {
      font-weight: 400;
      font-size: 32px;
      color: #000;
      line-height: 36px;
      white-space: pre-line;
    }
    &-author {
      color: #20262d;
      font-weight: 400;
      margin-top: 25px;
    }
    .abstract {
      margin-top: 25px;
      &-title {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
      }
      &-subtitle {
        margin-top: 20px;
      }
      &-text {
        margin-top: 22px;
        line-height: 24px;
        font-size: 18px;
      }
    }
  }
  .sidebar {
    border-left: 1px solid #ebecf2;
    padding: 25px 25px;
    font-style: normal;
    height: 100%;
    .header {
      .sub-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
          color: #20262d;
        }
        .filter {
          border: 1px solid #545464;
          border-radius: 8px;
          padding: 2px 6px;
          cursor: pointer;
        }
      }
      .desc {
        margin-top: 10px;
        color: #8c9296;
      }
    }
    .card-palette {
      margin-top: 1.8em;
      overflow-y: auto;
      height: calc(100% - 96px);
      // height: calc(100% - 78px);
      padding-bottom: 10px;
      scroll-behavior: smooth;
      .card:not(:first-of-type) {
        margin-top: 20px;
      }
      .card-task {
        // border: 1px solid #EBECF2;
        &__header {
          background: #1a6efd;
          color: #ffffff;
          padding: 15px;
        }
        &__sub-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .card-task__name {
            font-weight: 600;
            font-size: 14px;
          }
          .card-task__options {
            cursor: pointer;
          }
        }
        &__desc {
          font-weight: 400;
          font-size: 10px;
        }
        &__title {
          font-weight: 400;
          font-size: 16px;
          margin-top: 12px;
        }
        &__creator {
          font-weight: 400;
          font-size: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 4px;
          .creator-name-date {
            font-weight: 400;
            font-size: 10px;
            margin-left: 5px;
          }
        }
        &__body {
          padding: 15px;
          border: 1px solid #ebecf2;
          border-top: none;
          label {
            color: #20262d;
            margin-left: 10px;
          }
          div {
            vertical-align: middle;
          }
          div:not(:first-of-type) {
            margin-top: 8px;
          }
        }
      }
      .card-annot {
        &__header {
          background-color: #ffcdcd;
          padding: 15px;
          color: #20262d;
          cursor: pointer;
        }
        &__sub-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .card-annot-creator {
            display: flex;
            flex-direction: row;
            align-items: center;
            .card-annot-details {
              display: flex;
              flex-direction: column;
              margin-left: 5px;
              .card-annot-name {
                font-weight: 600;
                font-size: 14px;
              }
              .card-annot-date {
                font-weight: 400;
                font-size: 10px;
              }
            }
          }
        }
        &__options {
          cursor: pointer;
        }
        &__entity {
          font-weight: 400;
          font-size: 16px;
          margin-top: 12px;
        }
        &__body {
          .highlighted-text {
            padding: 15px;
            border: 1px solid #ebecf2;
            border-top: none;
          }
        }
        &-comment__header {
          padding: 20px 15px 17px 15px;
          color: #20262d;
          border: 1px solid #ebecf2;
          border-top: none;
        }
        &-comment__msg {
          margin-top: 10px;
          span {
            color: #0d99ff;
          }
        }
      }
    }
    
  }

  .sidebar2 {
      border-left: 1px solid #CCCAD1;
      padding: 25px 0px;
      font-style: normal;
      height: 100%;
      color: #18161B;
      display: flex;
      flex-direction: column;
      .header{
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .title{
          font-style: normal;
          font-weight: 700;

        }
      }
      .duplicate-card{
        padding: 0 15px 15px 15px;
        border-bottom: 1px solid #CCCAD1;
        .sub-title{
          font-size: 10px;
          padding: 10px 0;
        }
        &__header{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          .card-name{
            font-style: normal;
            font-weight: 500;
            margin-left: 10px;
            cursor: pointer;
            &.warn{
              color: #EE0B0B;
            }
            &.link{
              color: #06B54C;
            }
          }
          .duplicate-card__options{
            margin-left: auto;
            cursor: pointer;
          }
        }
        &__footer {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          .button{
            width: 100%;
          }
          .btn-cl{
            margin-right: 10px;
          }
        }
        &__body{
          max-height: 140px;
          overflow: auto;
        }
      }
      .card-task2{
        padding: 0 15px 15px 15px;
        color: #3C3A41;
        border-bottom: 1px solid #CCCAD1;
        &:hover{
          background-color: #FAFAFA;
          color: #18161B;
        }
        &__header{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          .card-name{
            font-style: normal;
            font-weight: 500;
            color: #7856FF;
            margin-left: 10px;
            cursor: pointer;
          }
          .card-task2__options{
            margin-left: auto;
            cursor: pointer;
          }
          .cancel-btn{
            margin-left: auto;
            color: #3C3A41;
            font-size: 14px;
            cursor: pointer;
          }
        }
        &__body {
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid #ebecf2;
          &.show{
            display: block;
          }
          &.hide{
            display: none;
          }
          label {
            font-style: normal;
            font-weight: 400;
            color: #3C3A41;
            margin-left: 10px;
          }
          div {
            vertical-align: middle;
          }
          .title {
            font-size: 12px;
          }
          input[type=radio] {
            border: 0px;
            width: 25px;
            height: 1.45em;
            accent-color: #3C3A41;
          }
          .radio-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;
            margin-bottom: 12px;
            .selected{
              color: #18161B;
            }
            label span{
              margin-left: 5px;
              color: #938d9d;
            }
          }
          .confirm-btn{
            width: 100%;
            padding: 10px;
            color: #FFFFFF;
            background-color: #7856FF;
            text-align: center;
            border-radius: 4px;
            margin: 15px 0 0 0;
            cursor: pointer;
            font-size: 14px;
            &.disable{
              background-color: #E5E4EB;
              color: #CCCAD1;
              cursor: not-allowed;
            }
          }
        }
        .cardsuggestion-confirm{
          margin: 10px 0 0 0;
        }
      }
      .card-palette2{
        overflow-y: auto;
        // height: calc(100% - 96px);
        padding-bottom: 10px;
        scroll-behavior: smooth;
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
        height:0;
        min-height: 0;
        .card-annot2{
          box-sizing: border-box;
          color: #3C3A41;
          border-bottom: 1px solid #CCCAD1;
          // padding-bottom: 15px;
          &:hover{
            background-color: #FAFAFA;
            color: #18161B;
          }
          &__header{
            padding: 0 15px;
            cursor: pointer;
            &_inner{
              display: flex;
              flex-direction: row;
              align-items: center;
              border-bottom: 1px solid #CCCAD1;
              padding: 14px 0;
              .dot-color{
                width: 17px;
                height: 17px;
                border-radius: 16px;
                margin-right: 10px;
              }
              .h-right{
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              .grp-len{
                margin-right: 6px;
              }
              .header-name{
                font-style: normal;
                font-weight: 500;
                color: #18161B;
              }
            } 
          }
          &__item{
            padding: 0 15px;
            // margin-right: 15px;
            // margin-left: 15px;
            &.active{
              border: 1px solid #7856FF;
            }
            &.selected{
              // border-top: 1px solid #CCCAD1;
              .card__comment__header{
                margin-bottom: 15px;
              }
            }
            &.selected {
              // border-bottom: 1px solid #CCCAD1;
            }
            .item__head{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .card-menu{
                margin-left: 10px;
              }
            }
            .highlighted-text{
              margin-top: 15px;
              margin-bottom: 15px;
              cursor: pointer;
              word-wrap: break-word;
              overflow: hidden;
            }
            .item__details{
              .card__comment{
                margin-top: 12px;
              }
            }
          }
        }
      }
      .cardsuggestion-confirm{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
        align-items: center;
        .accuracy-detail{
          width: 30%;
          &__label{
            font-size: 10px;
            color: #838188;
          }
          &__value{
            font-style: normal;
            font-weight: 500;
            color: #18161B;
            font-size: 14px;
          }
        }
        .suggestion-btn{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          .button{
            padding: 6px 24px;
          }
          &__cancel {
            padding: 6px 15px;
            border-radius: 4px;
            width: 100%;
            margin-right: 10px;
            text-align: center;
            cursor: pointer;
          }
          &__confirm{
            padding: 6px 15px;
            border-radius: 4px;
            width: 100%;
            text-align: center;
            cursor: pointer;
          }
        }
      }
  }

  .leftsidebar {
    height: 100%;
    border-right: 1px solid #CCCAD1;
    display:flex;
    flex-direction: column;
    svg {
      path {
        stroke: #36343B;
      }
    }
    .entities-menus{
      padding: 16px 15px;
      height: calc(100% - 65px);
      &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        .label{
          color: #18161B;
          font-style: normal;
          font-weight: 700;
        }
        .close-icon{
          path {
            stroke: #36343B;
          }
        }
      }
      &__list{
        overflow-y: auto;
        max-height: 100%;
        scroll-behavior: smooth;
        .menus-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-top-width: 2px;
          border-top-style: solid;
          padding: 14px 0;
          color:#36343B;
          cursor: pointer;
          &-z{
            display: flex;
            flex-direction: row;
            align-items: center;
            .label{
              font-style: normal;
              font-weight: 500;
            }
          }
        }
        .menus-sub{
          margin-top: -5px;
          margin-bottom: 14px;
          &-item{
            padding: 8px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            color:#36343B;
            .label{
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
            }
          }
      }
      }
    }
    // .secondary-menus{
    //   border-top: 1px solid #CCCAD1;
    // }
    .hide-panel{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: auto;
      padding: 15px 15px;
      .lefticon{
        margin-right: 10px;
      }
      .label{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        // line-height: 24px;
        color: #36343B;
      }
    }
  }
}

.card__creator-info{
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  &-name{
    font-style: normal;
    font-weight: 500;
    color: #18161B;
    margin-left: 10px;
    max-width: 120px;
  }
  &-date{
    color: #36343B;
  }
}
.card__comment{
  border-top: 1px solid #CCCAD1;
  &.active {
    border-top: 2px solid #7856FF;
  }
  &__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 15px 0;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
    .title{
      color: #18161B;
      font-style: normal;
      font-weight: 400;
    }
    .item-no{
      color: #A2A1A8;
      margin-left: 10px;
    }
    .dropdw-icon{
      margin-left: auto;
      cursor: pointer;
    }
  }
  &__body{
    padding-left: 20px;
    &-item{
      margin-top: 12px;
      margin-bottom: 15px;
      &:last-of-type{
        margin-bottom: 0px;
      }
      .card__creator-info{
        margin-top: 0;
      }
      .item-msg{
        margin-top: 6px;
        font-size: 0.9em;
        .mention-highlight{
          color: #7856FF;
        }
      }
    }
  }
  &__footer{
    position: relative;
    margin-top: 14px;
    margin-bottom: 15px;
    .card-annot-reply{
      border: none;
      padding: 0px;
      // .reply-input__input{
        // padding: 8px !important;
        // height: 35px !important;
      // }
    }
    textarea{
      max-height: 48px !important;
    }
    textarea, input{
      font-size: 14px !important;
      border-radius: 3px;
      outline: none;
      padding-right: 35px !important;
    }
    .reply-input__highlighter{
      font-size: 14px !important;
      height: 42px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .footer-icon{
      position: absolute;
      top: 30%;
      right: 16px;
      cursor: pointer;
    }
  }
}

.card-annot-reply {
  padding: 20px 15px 17px 15px;
  color: #20262d;
  border: 1px solid #ebecf2;
  border-top: none;
  .reply-input {
    &__control {
      background-color: #fff;
      font-size: 16px;
      font-weight: 'normal';
    }

    &.reply-input--multiLine{
      .reply-input__control {
        font-family: 'Georgia';
        // min-height: 63px;
      }
      .reply-input__highlighter {
        padding: 9px;
        border: 1px solid transparent;
        box-sizing: border-box;
      }
      .reply-input__input {
        padding: 7px 9px;
        border: 1px solid silver;
        height: 40px;
        overflow-y: auto !important;
      }
    }

    &.reply-input--singleLine{
        display: 'inline-block';
        // width: 180;
        .reply-input__highlighter {
          padding: 9px;
          border: 1px solid transparent;
          box-sizing: border-box;
        }
        .reply-input__input {
          padding: 9px;
          border: 1px solid silver;
          height: 42px;
          overflow-y: auto !important;
        }
    }
      &__suggestions {
        &__list {
          background-color: white;
          border: 1px solid rgba(0,0,0,0.15);
          font-size: 16;
        }
        &__item {
          padding: 5px 15px;
          border-bottom: 1px solid rgba(0,0,0,0.15);
        }
        &__item--focused {
          background-color: #cee4e5;
        }
      }
  }
}