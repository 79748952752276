.add-drug-modal{
    padding-top: 6px;
    .title{
        color: #18161B;
        font-style: normal;
        font-weight: 500;
    }
    .sub-title{
        color: #56555C;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        margin-top: 5px;
    }
    
    .footer{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        button.cancel{
            margin-right: 10px;
        }
    }
}