.revkey-modal{
    .modalContent{
        padding: 20px;
        margin-top: 0;
    }

    .revkey-set{
        width: 410px;
        padding: 10px;
        .revkey-header .title{
            color: #18161B;
            font-weight: 600;
            font-size: 18px;
        }
        .info{
            margin-top: 20px;
            color: #56555C;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            text-align: justify;
            line-height: 20px;
        }
        .inpt{
            display: flex;
            flex-direction: row;
            margin-top: 25px;
            input{
                flex: 1;
                background: #FFFFFF;
                border: 1px solid #E5E4EB;
                border-radius: 4px;
                outline: none;
                padding: 10px;
                color: #A2A1A8;
                &:focus{
                    outline: none;
                }
            }
        }

        .footer{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            button{
                font-weight: 500;
                font-style: normal;
                color: #FFFFFF;
                border-radius: 4px;
                padding: 10px 25px;
                outline: none;
                border: none;
                text-align: center;
                cursor: pointer;
                &.cancel{
                    margin-left: auto;
                    color: #3C3A41;
                    background: #F8F8FB;
                }
                &.revoke{
                    margin-left: 10px;
                    color: #EE0B0B;
                    background: #FFDADA;
                }
            }
        }
    }
}