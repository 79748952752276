.add-drug{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    padding: 4px 4px 4px 12px;
    border: 1px solid #E5E4EB;
    .label{
        color: #A2A1A8;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-right: 10px;
    }
}

.add-country{
    margin-right: 20px;
}

.add-drug-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 96px;
    padding: 5px 10px !important;
    color: #18161B !important;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: #E5E4EB !important;
    margin-left: 5px;
    &:hover{
      background-color: #E5E4EB;
    }
    &:active{
      color: #18161B;
    }
    svg {
        margin-left: 5px;
    }
}

.drug-edit{
    position: relative;
    display: inline-block;
    width: 170px;
    cursor: pointer;
    &:hover {
        .edit-ic{
            display: block;
        }
    }
    .drug-edit-btn{
        color: #838188 !important;
        font-style: normal;
        font-weight:500;
        font-size: 0.95rem;
        background: #F8F8FB !important;
        border-radius: 4px;
        padding: 11px 0px;
        text-align: center;
    }
    .edit-ic{
        position: absolute;
        top: 25%;
        right: 10px;
        display: none;
    }
}
