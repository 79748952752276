.widget-card {
    border-radius: 8px;
    background: #FFF;
    padding: 15px 20px 8px;
    .header{
        display: flex;
        flex-direction: row;
        .show-all{
            margin-left: auto;
            color: #838188;
            font-size: 14px;
            font-style: normal;
            cursor: pointer;
        }
        .widgetitle{
            color: #3C3A41;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }
        .reload{
            path {
                stroke: #838188;
            }
        }
    }
    .widget-footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 5px;
        height: 30px;
        .info{
            display: flex;
            flex-direction: row;
            align-items: center;
            color:#838188;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
        .info-icon{
            margin-right: 10px;
        }
    }
}