.upload_article__page {
  /*
    * File
    */

  .header-viewer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .file {
      position: relative;
      display: flex;
      max-width: 400px;
      min-width: 300px;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      height: 2.5rem;
      input {
        min-width: 14rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 0;
      }
      .file-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        padding: 0.5rem 1rem;
        color: #555;
        background-color: #fff;
        border: 0.075rem solid #ddd;
        border-radius: 0.25rem;
        box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:after {
          content: 'Choose file...';
        }

        &:before {
          position: absolute;
          top: -0.075rem;
          right: -0.075rem;
          bottom: -0.075rem;
          z-index: 6;
          display: block;
          content: 'Browse';
          padding: 0.5rem 1rem;
          color: #555;
          background-color: #eee;
          border: 0.075rem solid #ddd;
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
      input:focus ~ .file-custom {
        box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
      }
    }
    .upload-btn {
      padding: 0.6rem 1rem;
      color: #fff;
      border: 0.075rem solid #008cba;
      background-color: #008cba;
      outline: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .viewer_container {
    height: 750px;
    margin-top: 10px;
  }

  .dummy_preview {
    align-items: center;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    display: flex;
    font-size: 2rem;
    height: 100%;
    justify-content: center;
    width: 100%;
    color: rgb(174, 174, 174);
  }
}
