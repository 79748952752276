.menu-item-dropdown.assignee-set {
    width: 400px !important;
    padding: 10px 15px !important;
    top: 35px;
    .dropdown-header{
        padding: 0 10px;
        svg{
            cursor: pointer;
            path{
                stroke: #18161B;
            }
        }
    }
    .user-lst{
        height: auto;
        max-height: 280px;
    }
    .user-itm{
        cursor: pointer;
    }
    .all-item{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer;
    }
    .other-item{
        display: block;
    }
}

.datepicker-menu{
    width: auto !important;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    .filter-menu{
        border-right: 1px solid #E5E4EB;
        width: 150px;
        padding: 24px;
        &-it{
            padding: 10px 0;
            cursor: pointer;
            &:hover{
                color: #7856FF;
            }
        }
    }
    .rdp {
        margin: 0 !important;
    }
    .dateselect{
        padding: 30px 20px 25px 20px;
    }
    .rdc-footer{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        .action-btn{
            margin-left: auto;
            .button{
                margin-left: 10px;
            }
        }
    }
}
