.genkey-modal{
    .modalContent{
        padding: 20px;
        margin-top: 0;
    }

    .genkey-set{
        width: 410px;
        padding: 10px;
        .genkey-header .title{
            color: #18161B;
            font-weight: 600;
            font-size: 18px;
        }
        .info{
            margin-top: 20px;
            color: #56555C;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            text-align: justify;
            line-height: 20px;
        }
        .inpt{
            display: flex;
            flex-direction: row;
            margin-top: 25px;
            input{
                flex: 1;
                background: #FFFFFF;
                border: 1px solid #E5E4EB;
                border-radius: 4px;
                outline: none;
                padding: 10px;
                color: #A2A1A8;
                &:focus{
                    outline: none;
                }
            }
            button{
                background: #7856FF;
                border-radius: 4px;
                padding: 4px 7px;
                outline: none;
                border: none;
                text-align: center;
                vertical-align: middle;
                margin-left: 10px;
                cursor: pointer;
                svg{
                    vertical-align: middle;
                }
            }
        }

        .footer{
            margin-top: 20px;
            button{
                display: block;
                font-weight: 500;
                font-style: normal;
                background: #7856FF;
                color: #FFFFFF;
                border-radius: 4px;
                padding: 10px 8px;
                outline: none;
                border: none;
                text-align: center;
                cursor: pointer;
                margin-left: auto;
            }
        }
    }
}