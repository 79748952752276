.overview-page{
    display: flex;
    flex-direction: column;
    background-color: #F8F8FB;
    width: calc(100vw - 65px);
    height: calc(100vh - $dash-menu-bar-height);
    padding: 24px 35px;
    overflow: auto;
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left{
            .title{
                color: #18161B;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                margin-bottom: 8px;
            }
            .subtitle{
                color: #838188;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
            }
        }
        .right{
            .sortby{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #838188;
                .switch-btn{
                    margin-left: 20px;
                }
            }
        }
        
    }
    .cards{
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        gap: 20px;
        .left{
            display: flex;
            flex-direction: column;
            width: 50%;
            align-content: space-between;
            row-gap: 20px;
        }
        .right{
            display: flex;
            flex-direction: column;
            width: 50%;
            row-gap: 20px;
        }
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            .right,.left{
                width: 100%;
            };
        }
    }

    .empty-vw{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #F2F1F5;
        height: 100%;
        margin-top: 25px;
        .info-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            .title{
                margin-bottom: 20px;
                color: #18161B;
                font-style: normal;
                font-weight: 500;
            }
            svg path{
                stroke: #18161B;
            }
            .msg{
                color: #838188;
                text-align: center;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-top: 18px;
            }
            .add-prj{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                padding: 8px 28px;
                svg {
                    margin-left: 10px;
                    path{
                    stroke: #FFFFFF;
                }}
            }
        }
    }
}