.org-modal {
    .modalContent{
        padding: 20px;
        margin-top: 0;
    }
    .org-set{
        width: 450px;
        height: auto;
        .org-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .org-det{
                display: flex;
                flex-direction: column;
                .title{
                    color: #18161B;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }
                .subtitle{
                    color: #56555C;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .close-icon{
                cursor: pointer;
            }
        }
        .apikytb{
            font-size: 14px;
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 400;
            .bdy-abv{
                max-height: 300px;
                overflow: auto;
            }
            
            .info, .mgs{
                padding: 0 20px;
                text-align: justify;
                line-height: 20px;
            }
            .mgs{
                margin-top: 25px;
            }
            .keys-tb{
                margin-top: 20px;
                table{
                    width: 100%;
                    th{
                        color: #3C3A41;
                        font-style: normal;
                        font-weight: 700;
                        text-align: left;
                        padding: 10px 0;
                    }
                    td{
                        color: #3C3A41;
                        font-weight: 400;
                        padding: 10px 0;
                        text-align: left;
                        &.trash{
                            text-align: right;
                            svg{
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .footer{
                display: flex;
                margin-top: 20px;
                .crt-btn{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: #7856FF;
                    border-radius: 4px;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 15px;
                    padding: 10px 25px;
                    margin-left: auto;
                    cursor: pointer;
                    svg{
                        margin-right: 10px;
                        path{
                            stroke: #FFFFFF;
                        }
                    }
                }
            }
        }
        .webhktb{
            .info{
                color: #000000;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                text-align: justify;
                line-height: 20px;
            }
            .inpt{
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                input{
                    flex: 1;
                    background: #FFFFFF;
                    border: 1px solid #E5E4EB;
                    border-radius: 4px;
                    outline: none;
                    padding: 12px 10px;
                    color: #A2A1A8;
                    &:focus{
                        outline: none;
                    }
                }
            }
            .footer{
                display: flex;
                margin-top: 16px;
                .save-btn{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: #7856FF;
                    border-radius: 4px;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 15px;
                    padding: 10px 25px;
                    margin-left: auto;
                    cursor: pointer;
                    border: none;
                }
            }
        }
    }
}

// PENDING INVITE TAB
.pending-inv-tb{
    table{
        width: 100%;
        th{
            color: #3C3A41;
            font-style: normal;
            font-weight: 700;
            text-align: left;
            padding: 10px 0;
            &.email{
                width: 50%
            }
            &.date{
                width: 30%
            }
        }
        tr{
            margin-top: 10px;
        }
        td{
            padding: 7px 0;
            color: #3C3A41;
            font-weight: 400;
            text-align: left;
            &.created_at{
                font-size: 14px;
            }
            div.action {
                display: flex;
                justify-content: space-around;
                align-items: center;
                svg{
                    cursor: pointer;
                }
            }
        }
    }
    .n-pend{
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}