.enclosed-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &.qcpending, &.repending{
        path {
          stroke: #E1B000
        }
      }
      &.completed{
        path {
          stroke: #06B54C
        }
    }
      &.warning{
        path {
          stroke: #EE0B0B
        }
    }
}