.button {
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 4px;
    font-size: 0.9rem;
    outline: none;

    &.small{
      padding: 8px 20px;
      font-size: 0.6rem;
    }

    &.contained{
        background: #7856FF;
        color: #FFFFFF;
        border: none;
    }
    &.outlined{
        background: #FFFFFF;
        color: #7856FF;
        border: 1px solid #7856FF;
    }

    &.cta{
      background-color: #7856FF;
      color: #FFFFFF;
      &:hover{
        background-color: #4D29DB;
      }
      &:active{
        background-color: #4D29DB;
        color: #D0C8F3;
      }
      &.outlined{
        background: #FFFFFF;
        color: #7856FF;
        border: 1px solid #7856FF;
      }
    }
    &.negative{
      background-color: #FFDADA;
      color: #EE0B0B;
      &:hover{
        background-color: rgba(240, 12, 12, 0.40);
        color: #EE0B0B;
      }
      &:active{
        background-color: rgba(240, 12, 12, 0.40);
        color: #FFFFFF;
      }
    }
    &.secondary{
      background-color: #18161B;
      color: #FFFFFF;
      &:hover{
        background-color: #3C3A41;
        color: #FFFFFF;
      }
      &:active{
        background-color: #3C3A41;
        color: #CCCAD1;
      }
    }
    &.tint{
      background-color: #F8F8FB;
      color: #3C3A41;
      &:hover{
        background-color: #E5E4EB;
        color: #3C3A41;
      }
      &:active{
        background-color: #E5E4EB;
        color: #18161B;
      }
    }
    &.disable{
      background-color: #E5E4EB;
      color: #CCCAD1
    }

    &.normal{
      width: auto;
    }
    &.full{
      width: 100%;
    }

    &.cancelBtn {
        width: 100%;
        margin-top: 20px;
        border: none;
        color: #2c3e50;
        background: #f8f8fb;
        transition: all 0.25s ease;
      }
      
      &.cancelBtn:hover {
        box-shadow: none;
        transform: none;
        background: whitesmoke;
      }

      &.saveBtn{
        width: 100%;
        background: #7856FF;
        color: #FFFFFF;
      }
    
}

.content-type__button{
  border: 1px solid #E5E4EB;
  border-radius: 6px;
  cursor: pointer;
  .list-type{
    background: #18161B;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 6px 20px;
    svg{
      margin-right: 8px;
    }
  }
}

.icon-btn{
  width: 32px;
  height: 32px;
  background: #FFDADA;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 9px;
  margin: 0 22px;
  .view-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon path{
      stroke: #18161B;
    }
    &.disable{
      cursor: not-allowed;
      .icon{
        opacity: 0.5;
      }
    }
  }
}

.sort-btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path{
    stroke: #56555C;
  }
}

.button-train{
  cursor: pointer;
  .button{
    max-width: 96px;
    padding: 5px 10px;
    color: #18161B;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: #E5E4EB;
    margin-left: 5px;
    &:hover{
      background-color: #E5E4EB;
    }
    &:active{
      color: #18161B;
    }
  }
}

.switch-btn{
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #E5E4EB;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: #56555C;
  .s-btn{
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      border-radius: 4px;
      color: #18161B;
      background:  #E5E4EB;
    }
  }
}
