.link-duplicate-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 20px 5px 0;
    .text{
        font-style: normal;
        font-weight: 400;
        color: #3C3A41;
        margin-right: 20px;
    }
    .checkbox{
        margin-right: 10px;
    }
    .view-icon{
        margin-left: auto;
        cursor: pointer;
        path {
            stroke: #3C3A41;
        }
    }
}