.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  opacity: 1;
  animation-name: backdropAnimation;
  animation-duration: 0.2s;
  animation-direction: alternate;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1010;
}

.modal {
  /* width: 336px; */
  /* height: 170px; */
  background: white;
  /* color: white; */
  z-index: 1010;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  animation-name: modalAnimation;
  animation-duration: 0.1s;
  animation-direction: alternate;
}

@keyframes backdropAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalAnimation {
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
}

.modalHeader {
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 12px 0;
  padding: 10px;
  color: #18161b;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}

.modalContent {
  padding: 0 20px;
  margin-top: 10px;
  padding-bottom: 20px;
}
