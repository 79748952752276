.tabs{
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-bottom: 8px;
    &-item{
        white-space: nowrap;
        color: #56555C;
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
        padding: 10px 20px 10px 20px;
        &:first-of-type{
            padding: 10px 20px 10px 0px;
        }
        border-bottom: 2px solid #F8F8FB;
        cursor: pointer;
        &.active {
            border-bottom: 2px solid #7856FF;
            color: #18161B;
            font-style: normal;
            font-weight: 500;
        }
        &.filler {
            flex: 1;
            cursor: default;
        }
    }
}