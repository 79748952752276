.invite-inpt{
    display: flex;
    flex-direction: row;
    input{
        flex: 1;
        background: #FFFFFF;
        border: 1px solid #E5E4EB;
        border-radius: 4px;
        padding: 12px 10px;
        &:focus{
            outline: none;
        }
    }
    button {
        margin-left: 10px;
        background: #7856FF;
        border-radius: 4px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 15px;
        padding: 10px 30px;
        cursor: pointer;
        border: none;
    }
}
.invite-sect{
    .error{
        font-size: 10px;
        color: red;
    }
}
.confirmationpop-modal{
    [class*="Modal_heading"]{
        margin-bottom: 0;
    }
    .msg{
        text-align: center;
        font-size: 0.9rem;
        color: #56555C;
    }
    .action-btn{
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        .cancelBtn{
            margin-top: 0;
        }
    }
    .modalContent{
        padding-top: 0;
    }
}