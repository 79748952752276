.unassign-wgt{
    .button{
        font-size: 0.8rem !important;
    }
    .completed-itm{
        padding: 5px 0;
    }
    .unassign-main{
        height: 166px !important;
    }
    .assign-to{
        display: flex;
        flex-direction: row;
        align-items: center;
        .txt{
            color:#838188;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-right: 25px;
        }
        .usr{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            &-name{
                color:#3C3A41;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-left: 10px;
            }
        }
        .usr-cot{
            position: relative;
        }
        .usr-lst{
            position: absolute;
            border-radius: 4px;
            border: 1px solid #E5E4EB;
            padding: 0px 10px;
            background-color: #FFF ;
            top: 28px;
            z-index: 10;
            .usr{
                margin: 4px 0;
            }
        }
    }
}
.popover-usr-lst{
    border-radius: 4px;
    border: 1px solid #E5E4EB;
    padding: 0px 10px;
    background-color: #FFF;
    .usr{
        margin: 4px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &-name{
            color:#3C3A41;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-left: 10px;
        }
    }
}