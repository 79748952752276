.copylk{
    margin-left: auto;
    color: #18161B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg{
        margin-right: 5px;
    }
}