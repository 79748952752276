.userightb{

}

.user-lst{
    padding: 10px 0;
    max-height: 218px;
    overflow-y: auto;
    .user-itm{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
}

.user-dt{
    display: flex;
    flex-direction: row;
    align-items: center;
    .user-txt{
        color: #3C3A41;
        margin-left: 10px;
        .user-nm{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            .user-rl{
                font-size: 10px;
                border: 1px solid #D0C8F3;
                border-radius: 4px;
                padding: 2px;
                margin-left: 6px;
                text-transform: capitalize;
                &.tag{
                    display: inline-block;
                }
            }
        }
        &.inv{
            color: #838188;
        }
        .user-em{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            margin-top: 5px;
        }
    }
}