.document_page_skel{
    padding-top: 60px;
}

.doc-table-skel{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
    &.th > span{
        width: 100%;
    }
}

.user-skel,.doc-status-skel, .table-option-skel, .doc-title-skel, .head-filter-skel, .doc-head-skel, .doc-head-skel .left{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-skel{
    & > span{
        margin-left: 10px;
    }
}

.doc-status-skel{
    & > span{
        margin-left: 10px;
    }
}
.doc-title-skel, .head-filter-skel{
    .box{
        margin-right: 20px;
    }
}
.head-filter-skel{
    .box{
        margin-right: 10px;
    }
}
.table-option-skel{
    & > span{
        margin-left: 20px;
    }
}
.doc-head-skel{
    justify-content: space-between;
    margin-bottom: 15px;
    .left .head-filter-skel{
        margin-right: 30px;
    }
}