.rdp {
    --rdp-caption-font-size: 14px !important;
    --rdp-cell-size: 34px !important;
    --rdp-accent-color: #F8F8FB !important;
}
.rdp-table {
    border-spacing: 0 3px;
    border-collapse: separate !important;
}
.rdp-caption_label{
    color:  #3C3A41 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
}
.rdp-head_cell{
    color: #CCCAD1 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}
.rdp-button_reset{
    color: #838188 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.rdp-day_today{
    background-color: #D0C8F3 !important;
    color: #18161B !important;
    border-radius: 6px !important;
}
.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
    color: #56555C;
    background-color: var(--rdp-accent-color);
}
.rdp-weeknumber, .rdp-day{
    border-radius: 0 !important;
}
.rdp-day_range_start, .rdp-day_range_end{
    background-color: #7856FF !important;
    color: #fff !important;
}
.rdp-day_range_start, .rdp-day_range_end{
    border-radius: 5px !important;
}
.rdp-cell:has(.rdp-day_range_start) {
    background-color: #F8F8FB !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.rdp-cell:has(.rdp-day_range_end) {
    background-color: #F8F8FB !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.rdp-nav_button {
    width: 22px !important;
    height: 22px !important;
}

// .rdp-dropdown option{
//     background-color: #fff !important;
//     color: #000 !important;

//     background: rgb(194, 222, 209);
//   border: 3px solid rgb(84, 73, 116);
//   width: 300px;
//   height: 30px;
//   font-size: large;
// }