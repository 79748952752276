.dash-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFF;
    border-top: 1px solid #E5E4EB;
    padding: 0px 50px;
    height: $dash-footer-height;
    font-size: 14px;
}
.page-contrl{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pagination{
    display: flex;
    flex-direction: row;
}

.prev-pg-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    &.active{
        svg path{stroke: #18161B;}
    }
}

.next-pg-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    &.active{
        svg path{stroke: #18161B;}
    }
}

.pg-num{
    padding: 8px;
    color: #A2A1A8;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    &.ext{
        pointer-events: none;
    }
    &.active, &:hover{
        color: #18161B;
    }
}

.pagination-count{
    color: #A2A1A8;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}