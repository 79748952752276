.app-pop-modal{
    border: 1px solid #E5E4EB !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08) !important;
    border-radius: 4px !important;
    background: #FFFFFF !important;
    .modalContent{
        margin-top: 0;
        padding-bottom: 0;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #838188;
        line-height: 24px;
        .reload-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #3C3A41;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            svg {
                margin-left: 10px;
                path{
                    stroke: #3C3A41;
                }
            }
        }
    }
}
.app-pop-wrap {
    top: 25% !important;
}